@import "../../_globals/variables";

.hero_alt {
    padding: 100px 0 100px $padding;
    min-height: 90vh;
    display: flex;
    align-items: center;
    background-color: #ffffff; //#f1f1f1;
    position: relative;

    @media only screen and (max-width: 767px) {
        flex-direction: column-reverse;
        padding: 50px 0;
        align-items: flex-start;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        min-height: 70vh;
    }

    &__gradient {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        height: 200px;
    }

    &__left {
        width: 40%;

        @media only screen and (max-width: 767px) {
            width: auto;
            padding: 50px $padding;
        }

        h1 {
            max-width: 80%;

            @media only screen and (max-width: 767px) {
                max-width: 100%;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                max-width: 100%;
            }
        }

        &_content {
            margin: 20px 0 40px;
            max-width: 85%;

            @media only screen and (max-width: 767px) {
                max-width: 100%;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                max-width: 100%;
            }

            p {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        small {
            display: block;
            font-size: clamp(15px, 1vw, 16px);
            margin: 30px 50px 20px 0;

            @media only screen and (max-width: 767px) {
                margin: 30px 0 20px;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                margin: 30px 0 20px;
            }
        }
    }

    &__right {
        width: 55%;
        // margin-left: auto;

        @media only screen and (max-width: 767px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &_lottie {
            &_desktop {
                max-width: 55vw;

                @media only screen and (max-width: 767px) {
                    display: none;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    display: none;
                }
            }
        }

        &_image {
            // display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: flex-end;
            z-index: 2;

            &_desktop {
                max-width: 55vw;

                @media only screen and (max-width: 767px) {
                    display: none;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    display: none;
                }

            }

            &_mobile {
                display: none;

                @media only screen and (max-width: 767px) {
                    display: block;
                    max-width: 90vw;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    display: block;
                    max-width: 90%;
                    margin-left: 20px;
                }

            }
        }

        &_download {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            background: linear-gradient(to right, #44dcb7, #35e0f4, rgba(68, 220, 183, 1));
            display: flex;
            align-items: center;
            padding: 20px 50px;
            z-index: 0;
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                top: 0;
                transform: translateX(100%);
                width: 100%;
                height: 220px;
                position: absolute;
                z-index: 1;
                animation: slide 3s infinite;
                background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
            }

            @keyframes slide {
                0% {
                    transform: translateX(-100%);
                }

                100% {
                    transform: translateX(100%);
                }
            }

            @media only screen and (max-width: 767px) {
                border-radius: 0;
                padding: 10px 0;
                align-items: center;
                justify-content: center;
                width: 100%;
                flex-wrap: wrap;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                align-items: center;
                justify-content: center;
                width: 100%;
                flex-wrap: wrap;
                padding: 10px 0;
            }

            h4 {
                margin-right: 50px;
                text-transform: uppercase;

                @media only screen and (max-width: 767px) {
                    margin-right: 20px;
                    text-align: center;
                    font-size: 4vw;
                    letter-spacing: 1px;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    margin-right: 20px;
                    text-align: center;
                    letter-spacing: 1px;
                    font-size: 2vw;
                }
            }

            &_icons {
                a {
                    display: inline;
                }

                img {
                    width: 30px;
                    height: 35px;
                    margin-right: 30px;
                    object-fit: contain;

                    @media only screen and (max-width: 767px) {
                        width: 20px;
                        height: 25px;
                        margin-right: 20px;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        width: 20px;
                        height: 25px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}