body {
    font-family: 'Spartan', sans-serif;
    font-size: clamp(14px, 1vw, 16px);
    color: $charcoal;
    line-height: 28px;

    a {
        text-decoration: none;
        color: $link;

        &:hover {
            color: $charcoal;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Spartan', sans-serif;
    font-weight: bold;
}

h1 {
    font-size: clamp(26px, 2.6vw, 55px);
    line-height: 150%;
}

h2 {
    font-size: clamp(25px, 2vw, 45px);
    line-height: 150%;
}

h3 {
    font-size: clamp(16px, 1.15vw, 24px);
    line-height: 24px;
    letter-spacing: 4px;
    text-transform: uppercase;
}

h4 {
    font-size: clamp(20px, 1.25vw, 28px);
    line-height: 24px;
    letter-spacing: 4px;
}

h5 {
    font-size: clamp(15px, 1vw, 18px);
    font-weight: 500;
    line-height: 1.85;
    letter-spacing: 4px;
}

ul {
    padding-left: 20px;
    list-style-image: url('./list-marker.svg');

    li {
        padding-left: 10px;
    }
}

article {
    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}