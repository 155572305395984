@import "../../../_globals/variables";

.section {
    overflow-x: hidden;
    position: relative;
    background-color: #ffffff;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        width: 592px;
        height: 598px;
        background: linear-gradient(227.15deg, #44DCB7 -20.14%, rgba(68, 220, 183, 0) 48.99%);
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
}

.container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.row {
    padding: 120px 0 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}

.left_col {
    width: 490px;
}

.right_col {
    width: 590px;
}

.title {

    h1 {
        color: #000;
    }
}

.content {
    margin-top: 6px;

    p {
        color: #1A1A1A;
    }
}

.info_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -50px;
}

.info_col {
    width: 33.33333%;
    padding: 12px 50px;

    a {
        cursor: pointer;
    }
}

.info_title {
    margin-top: 8px;
    text-align: center;
    font-weight: 700;
    line-height: 1.538;
    color: #1a1a1a;
}

.download_row {
    padding: 0 0 48px 0;
    display: flex;
    justify-content: flex-end;
}

.download {
    position: relative;
    z-index: 2;
    width: 570px;
    padding: 16px 0;

    &::after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        right: calc(600px - 50vw);
        bottom: 0;
        background: linear-gradient(127.45deg, #44DCB7 23.85%, #35E0F4 96.39%);
        border-radius: 100px 0 0 100px;
        z-index: 1;
    }
}

.download_inner_row {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
}

.download_label {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.12;
    letter-spacing: 4px;
    color: #1a1a1a;
    padding: 12px 0 0 48px;
}

.download_link_row {
    display: flex;
    align-items: flex-end;
}

.download_playstore {
    display: flex;

    img {
        width: 32px;
    }
}

.download_appstore {
    display: flex;
    margin-left: 32px;
}

.download_deskstore {
    display: flex;
    margin-left: 32px;
}

.scroll_bottom {
    position: absolute;
    left: 0;
    bottom: 48px;
    display: flex;
    animation: pulse 2s infinite;

    @keyframes pulse {
        0% {
            transform: scale(0.75);
        }

        70% {
            transform: scale(1);
        }

        100% {
            transform: scale(0.75);
        }
    }
}

@media(max-width: 1300px) {

    .container {
        padding: 0 30px;
    }

    .download {

        &::after {
            right: -60px;
        }
    }

    .scroll_bottom {
        left: 30px;
    }

    .right_col {
        width: 400px;
    }

    .info_row {
        margin: 0 -12px;
    }

    .info_col {
        padding: 10px 12px;
    }
}

@media(max-width: 1000px) {

    .section {

        &::before {
            width: 375px;
            height: 379px;
            background: linear-gradient(227.15deg, #44DCB7 -20.14%, rgba(68, 220, 183, 0) 48.99%);
        }
    }

    .row {
        display: block;
        padding: 60px 0 0;
    }

    .left_col {
        width: 100%;
        margin-top: 8px;
    }

    .right_col {
        width: 100%;
    }

    .title {

        h1 {
            font-size: 28px;
            line-height: 1.429;
        }
    }

    .content {
        margin-top: 4px;
    }

    .download_row {
        padding: 0;
        margin: 30px -45px 0;
    }

    .download {
        width: 100%;
        padding: 14px 45px 12px;

        &::after {
            right: 0;
            border-radius: 0;
            background: linear-gradient(127.45deg, #44DCB7 23.85%, #35E0F4 96.39%);
            opacity: 0.9;
        }
    }

    .download_label {
        padding: 6px 0 0 0;
        font-size: 12px;
        line-height: 1.12;
        letter-spacing: 2.13px;
    }

    .download_playstore {

        img {
            width: 21px;
        }
    }

    .download_appstore {
        margin: 0 0 0 18px;

        svg {
            width: 20px;
            height: 24px;
        }
    }

    .download_deskstore {
        margin: 0 0 0 18px;

        svg {
            width: 23px;
            height: 20px;
        }
    }

    .scroll_bottom {
        display: none;
    }
}