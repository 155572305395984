@import "./_globals/_variables.scss";
@import "./_globals/_typography.scss";

* {
    margin: 0;
    padding: 0;
}

/* WebKit/Blink Browsers */
::selection {
    background: $secondary;
    color: $primary;
}

/* Gecko Browsers */
::-moz-selection {
    background: $secondary;
    color: $primary;
}

.MuiToolbar-gutters {
    padding: 10px 40px !important;
}