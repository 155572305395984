@import "../../_globals/variables";

.tweets {
    padding: 40px $padding 120px;

    @media only screen and (max-width: 767px) {
        padding: 20px 0 70px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        padding: 50px $padding 100px;
    }

    .slick-next,
    .slick-prev {

        @media only screen and (max-width: 767px) {
            top: auto;
            bottom: -15px;
        }

        &::before {
            font-size: clamp(25px, 2vw, 45px);
            color: $charcoal;
            opacity: 1;
        }
    }

    .slick-prev {
        @media only screen and (max-width: 767px) {
            left: 40%;
        }

        &::before {
            content: url("./carousel-prev.svg");
        }
    }

    .slick-next {
        @media only screen and (max-width: 767px) {
            right: 40%;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            right: -35px;
        }

        &::before {
            content: url("./carousel-next.svg");
        }
    }

    .slick-dots li button:before,
    .slick-next:before,
    .slick-prev:before {
        font-family: inherit;
    }

    &__wrapper {
        max-width: 80%;
        margin: 20px auto 50px 25px;
        box-shadow: $box-shadow;
        border-radius: 5px;
        background-color: $white;
        transition: transform .4s ease-out;
        padding: 30px 30px 50px;
        min-height: 260px;

        &:hover {
            transform: translateY(-20px);
        }

        @media only screen and (max-width: 767px) {
            margin: 20px 20px 50px 35px;
            max-width: 70%;
            min-height: auto;
            padding: 30px 20px;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            max-width: 70%;
        }


        &_top {
            display: flex;
            align-items: center;

            img {
                width: 48px;
                height: 48px;
                object-fit: contain;
                margin-right: 10px;
            }

            h6 {
                font-size: clamp(14px, 1vw, 16px);
                line-height: 1;
                margin-bottom: 5px;
            }

            P {
                line-height: 1;

                a {
                    font-size: clamp(12px, .9vw, 15px);
                    color: $charcoal;
                }
            }
        }

        &_bottom {
            margin-top: 20px;

            a {
                font-weight: bold;
                text-decoration: underline;
            }

            p {
                margin-bottom: 10px;
                line-height: 28px;

                img{
                    width: 18px;
                    display: inline-block;
                    margin: 0 1.125px;
                }
            }
        }
    }
}