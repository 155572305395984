@import "../../_globals/variables";

.legal {
    padding: 30px $padding;
    font-weight: 600;
    text-align: center;
    max-width: 55%;
    margin: 0 auto;

    @media only screen and (max-width: 767px) {
        max-width: 100%;
    }


    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
    }
}