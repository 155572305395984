.apisPage .downloadApp {margin-top: 40px !important;float:left}
.apisPage .mainHero {background: rgb(124,228,202);background: linear-gradient(223deg, rgb(124, 228, 202) 0%, rgb(241, 241, 241) 24%);}
.apisPage .mainFeatures {background-color: #f1f1f1;padding-top: 100px;}
.apisPage .allFeatures {display: flex;flex-wrap: wrap;margin-top: 100px;}
.apisPage .mainBlock {padding-top: 160px;background: rgb(124,228,202);background: linear-gradient(14deg, rgb(183, 233, 228) 13%, rgb(241, 241, 241) 66%);overflow: hidden;}
.apisPage .mainBlock .mainContent {display: flex;flex-wrap: wrap;}
.apisPage .mainBlock .mainContent .division {width: 100%;float: left}
.apisPage .mainBlock .mainContent .division#left {max-width: 45%;}
.apisPage .mainBlock .mainContent .division#left .divisionIn {height: 100%;display: grid;align-content: center;align-items: center;padding-right: 45px;}
.apisPage .mainBlock .mainContent .division#left h2 {font-size: 42px;line-height: 63px;}
.apisPage .mainBlock .mainContent .division#left .mainP {margin-top: 18px;}
.apisPage .mainBlock .mainContent .division#left .ctaBlue {margin-top: 19px;}
.apisPage .mainBlock .mainContent .division#right {max-width: 55%;}
.apisPage .mainBlock .mainContent .division#right .divisionIn img {width: 884px;margin-bottom: -100px;margin-right: 0;margin-left: auto;display: block;}

@media screen and (min-width: 1750px) {
    .apisPage .mainHero .mainContent .division#right .divisionIn > img {margin-right: -190px !important;width: 100% !important;}
}

@media screen and (max-width: 1500px) {
    .apisPage .mainHero .mainContent .division#left .divisionIn {padding-right: 0px;max-width: 497px;}
    .apisPage .mainBlock .mainContent .division#left h2 {max-width: 487px;}
    .apisPage .allFeatures .featureSingle h3 {max-width: 315px;}
    .apisPage .allFeatures .featureSingle .mainP {margin-top: 26px;}
}

@media screen and (max-width: 1200px) {
    .apisPage .mainFeatures {padding-top: 60px;}
    .apisPage .mainBlock {padding-top: 60px;}
    .apisPage .mainBlock .mainContent {display: block;}
    .apisPage .mainBlock .mainContent .division {max-width: 100% !important;}
    .apisPage .mainBlock .mainContent .division#right .divisionIn img {margin-right: auto;width: 100%;max-width: 790px;}
    .apisPage .mainBlock .mainContent .division#left .divisionIn {padding: 0px;}
}

@media screen and (max-width: 991px) {    
    .apisPage .mainHero {padding-bottom: 18px;}
    .apisPage .mainFeatures {background: rgb(241,241,241);background: linear-gradient(0deg, rgb(255, 255, 255) 69%, rgb(201, 245, 234) 100%);padding-bottom: 53px;}
    .apisPage .mainFeatures h2 {text-align: center;}
    .apisPage .mainBlock {padding-top: 60px;background: #F1F1F1;}
    .apisPage .mainBlock .mainContent .division#left h2 {text-align: center;}
    .apisPage .mainBlock .mainContent .division#left .mainP, .apisPage .mainBlock .mainContent .division#left .mainP + div {text-align: center;}
}

@media screen and (max-width: 450px) {
    .apisPage .mainBlock .mainContent .division#left h2 {text-align: center;}
    .apisPage .mainBlock .mainContent .division#left .mainP {text-align: center;}
    .apisPage .mainBlock .mainContent .division#left .mainP + div {text-align: center;}
    .apisPage .mainBlock .mainContent .division#right .divisionIn img {margin-bottom: -30px;}
}