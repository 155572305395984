@import "../../_globals/variables";

.home__reward {
    background: $primary;
    padding: 10px;
    color: white;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    margin-top: 80px;

    &:hover {
        color: white;
    }

    &_item {
        width: 100vw;
        padding: 5px;

        @media only screen and (max-width: 600px) {
            font-size: 12px;
        }
    }

    &_icon {
        margin-bottom: -5px;
        margin-right: 5px;
    }

    &_text_blue {
        color: #2ec2e6;
    }
}