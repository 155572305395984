@import "../../_globals/variables";

.demo_case_study {

    @media (min-width: 769px) {
        display: flex;
        justify-content: center;
    }

    &__case {
        @media (min-width: 769px) {
            margin-left: 20px;
        }
    }
}