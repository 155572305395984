@import "../../../_globals/variables";

.section {

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
}

.section+.section {

    .container {
        border-top: 1px solid #C7C7C7;
    }
}

.container {
    padding: 72px 25px;
    max-width: 1000px;
    margin: 0 auto;
}

.header_row {
    display: flex;
    align-items: center;
}

.header_right_col {
    padding-left: 50px;
}

.header_right_content {

    h2 {
        line-height: 1.5;
    }

    p {
        margin-top: 9px;
        line-height: 2.154;
    }
}

.feature_information {
    margin-top: 35px;
}

.feature_title {

    h3 {
        line-height: 1.5;
        letter-spacing: 4px;
        color: $charcoal;
    }
}

.feature_row {
    margin-top: 41px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 120px;
    grid-row-gap: 35px;
}

.feature_number {

    span {
        display: inline-block;
        width: 47px;
        height: 47px;
        border-radius: 50%;
        background: linear-gradient(127.45deg, #44DCB7 23.85%, #35E0F4 96.39%, rgba(68, 220, 183, 0) 96.39%);
        font-size: 24px;
        color: $white;
        text-align: center;
        font-weight: 700;
        padding-top: 13px;
    }
}

.feature_content {
    margin-top: 16px;

    p {
        color: $charcoal;
    }
}

.component_information {
    margin-top: 50px;
}

.component_title {

    h3 {
        letter-spacing: 4px;
        color: $charcoal;
    }
}

.component_row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 120px;
    grid-row-gap: 35px;
    margin-top: 35px;
}

.component_name {

    h4 {
        margin-bottom: 10px;
        line-height: 1.5;
        color: $charcoal;
        letter-spacing: 0;
    }
}

.component_content {

    p {
        color: $charcoal;
    }
}

.button_list {
    margin-top: 50px;
    display: flex;
}

.button_col {

    a {
        font-size: clamp(15px, .9vw, 16px);
        font-weight: 600;
        line-height: 1.48;
        display: inline-block;
        border-radius: 40px;
        padding: 13px 22px;
        color: $charcoal;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: 2px solid transparent;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $light-gradient;
            border-radius: 40px;
            z-index: -2;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background: $white;
            transition: all .3s;
            border-radius: 40px;
            z-index: -1;
        }

        &:hover,
        &:active:focus,
        &:focus,
        &:active {
            color: $charcoal;
            border: 2px solid $charcoal;
            box-shadow: none;

            &:before {
                width: 100%;
            }
        }
    }
}

.button_col+.button_col {
    margin-left: 24px;
}

@media(max-width: 1000px) {

    .section {
        padding: 0 45px;

        &:last-child {
            padding-bottom: 32px;
        }
    }

    .container {
        padding: 32px 0;
    }

    .header_row {
        display: block;
    }

    .header_right_col {
        padding-left: 0;
        margin-top: 6px;
    }

    .header_right_content {

        h2 {
            font-size: 28px;
            line-height: 1.429;
        }

        p {
            margin-top: 0;
        }
    }

    .feature_information {
        margin-top: 26px;
    }

    .feature_row {
        margin-top: 0;
        display: block;
    }

    .feature_col {
        width: 100%;
        display: flex;
        padding: 11px 0;
    }

    .feature_content {
        margin-top: 0;
        padding-left: 20px;
    }

    .component_information {
        margin-top: 35px;
    }

    .component_row {
        margin-top: 6px;
        display: block;
    }

    .component_col {
        padding: 12px 0;
    }

    .button_list {
        display: block;
        margin-top: 11px;
    }

    .feature_number {
        padding-top: 7px;
    }

    .button_col+.button_col {
        margin: 20px 0 0 0;
    }

    .header_left_col {

        img {
            width: 100px;
        }
    }
}