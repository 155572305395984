.section {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 29px 0 55px;
}

.sticky_section {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 29px 0 55px;
    display: none;
}

.section__is_sticky {
    display: block;
    position: fixed;
    top: 84px;
    left: 0;
    right: 0;
    z-index: 1000;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease-out;
    transform: translateY(-100%);
}

.section__is_sticky_show {
    transform: translateY(0);
}

.container {
    max-width: 1245px;
    margin: 0 auto;
}

.row {
    display: flex;
    justify-content: space-between;
}

.col {
    text-align: center;
}

.nav {
    cursor: pointer;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    &:hover {

        span {
            color: #1132A3;
        }
    }

    span {
        font-weight: 700;
        line-height: 1.48;
        color: #A1A1A1;
        transition: color 0.2s ease-out;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -18px;
        height: 5px;
        background: linear-gradient(89.97deg, #35E0F4 -11.47%, #33D7F0 -3.71%, #2EBDE4 9.2%, #2594D0 25.64%, #195BB6 44.81%, #1132A3 57.23%, #1132A3 99.98%);
        opacity: 0;
        transition: opacity 0.2s ease-out;
    }
}

.nav_active {

    &::after {
        opacity: 1;
    }
}

@media(max-width: 1300px) {

    .container {
        padding: 0 30px;
        overflow-x: auto;
    }
}

@media(max-width: 1150px) {

    .section {
        padding: 0;
    }

    .sticky_section {
        padding: 0;
    }

    .section__is_sticky {
        top: 76px;
    }

    .nav {

        &::after {
            bottom: -12px;
        }
    }

    .row {
        width: 1245px;
        padding: 18px 0 37px;
        margin-right: -30px;
        padding-right: 30px;
    }
}