@import "../../_globals/variables";

.home {
    &__features {
        padding: 100px $padding 50px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;

        @media only screen and (max-width: 767px) {
            padding: 60px $padding;
        }

        h2 {
            text-align: center;
        }

        &_cards {

            &_wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 70px 0;

                @media only screen and (max-width: 767px) {
                    flex-direction: column;
                }

                &:nth-child(odd) {
                    flex-direction: row-reverse;

                    @media only screen and (max-width: 767px) {
                        flex-direction: column;
                    }
                }

                &_left {
                    width: 50%;
                    transform: translatey(0px);
                    animation: float 6s ease-in-out infinite;

                    @media only screen and (max-width: 767px) {
                        width: 100%;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        width: 45%;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: contain;
                        transition: all .4s ease-in-out;

                        @keyframes float {
                            0% {
                                transform: translatey(0px);
                            }

                            50% {
                                transform: translatey(-20px);
                            }

                            100% {
                                transform: translatey(0px);
                            }
                        }
                    }
                }

                &_right {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media only screen and (max-width: 767px) {
                        width: 100%;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        width: 55%;
                    }

                    &_content {
                        max-width: 70%;
                        margin: 0 auto;

                        @media only screen and (max-width: 767px) {
                            max-width: 100%;
                        }

                        @media only screen and (min-width: 768px) and (max-width: 1024px) {
                            max-width: 85%;
                        }


                        img {
                            width: 80px;
                            height: 80px;
                            object-fit: contain;
                            margin-bottom: 10px;
                        }

                        &_lottie {
                            height: 140px;
                            width: 140px;
                            object-fit: contain;
                            margin-left: -40px;
                        }

                        h5 {
                            font-weight: bold;
                            letter-spacing: normal;
                            margin: 15px 0;
                        }

                        p {
                            margin-bottom: 15px;
                        }

                        ul {
                            @media only screen and (max-width: 767px) {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    // v2 styling
    &__v2 {
        &_features {
            &_cards {
                padding: 50px 0 0;
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-template-rows: 1fr;
                gap: 50px 70px;
                grid-template-areas:
                    ". . .";

                @media only screen and (max-width: 767px) {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    grid-template-areas:
                        ".";
                    gap: 0;
                    padding: 0;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    grid-template-areas:
                        "..";
                    padding: 20px 0;
                }

                &_wrapper {
                    &_content {
                        img {
                            width: 80px;
                            height: 80px;
                            object-fit: contain;
                            margin-bottom: 10px;
                        }

                        &_lottie {
                            height: 140px;
                            width: 140px;
                            object-fit: contain;
                            margin-left: -40px;
                        }

                        h5 {
                            font-weight: bold;
                            letter-spacing: normal;
                            margin: 15px 0;
                        }

                        p {
                            margin-bottom: 15px;
                        }

                        ul {
                            @media only screen and (max-width: 767px) {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    &__solutions {
        padding: 50px $padding 100px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 80%;
        margin: 0 auto;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
            max-width: 100%;
            padding: 0 $padding;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            max-width: 100%;
            padding: 0 $padding 30px 0;
        }

        &_left {
            display: flex;
            justify-content: flex-start;
            width: 55%;
            position: relative;

            @media only screen and (max-width: 767px) {
                width: 100%;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 50%;
            }

            img {
                width: 25vw;
                height: auto;
                object-fit: contain;

                &:nth-child(1) {
                    margin-left: -20vw;

                    @media only screen and (max-width: 767px) {
                        margin-left: -40vw;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        margin-left: -25vw;
                    }
                }

                &:nth-child(2) {
                    margin-left: -7vw;

                    @media only screen and (max-width: 767px) {
                        margin-left: -15vw;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        margin-left: -10vw;
                    }
                }

                &:nth-child(3) {
                    margin-left: -7vw;

                    @media only screen and (max-width: 767px) {
                        margin-left: -15vw;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        margin-left: -50px;
                        display: none;
                    }
                }

                @media only screen and (max-width: 767px) {
                    width: 50vw;
                    margin-left: -50px;
                    margin-bottom: 50px;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 40vw;
                    margin-left: -50px;
                    margin-bottom: 50px;
                }
            }
        }

        &_right {
            width: 45%;
            margin-bottom: 100px;

            @media only screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 50px;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 50%;
            }

            p {
                margin: 20px 0 30px;
            }

            &_content {
                max-width: 100%;

                &_btns {
                    display: flex;
                    flex-wrap: wrap;

                    @media only screen and (max-width: 767px) {
                        flex-direction: column;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        flex-direction: column;
                    }

                    section {
                        margin-right: 20px;
                        margin-bottom: 20px;

                        @media only screen and (max-width: 767px) {
                            margin-right: 0;
                            margin-bottom: 30px;
                        }

                        @media only screen and (min-width: 768px) and (max-width: 1024px) {
                            margin-right: 0;
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }

    &__featured {
        padding: 150px $padding 50px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
    }

    &__medium {
        padding: 0 $padding;
        display: flex;
        justify-content: space-between;
        margin: 0 25px;

        @media only screen and (max-width: 767px) {
            display: block;
        }

        h2 {
            @media only screen and (max-width: 767px) {
                text-align: center;
                max-width: 75%;
                margin: 0 auto;
            }
        }

        &_btn {
            @media only screen and (max-width: 767px) {
                display: none;
            }

            a {
                font-size: clamp(13px, .9vw, 16px);
                font-weight: bold;
                color: $black;
                display: flex;
                align-items: center;

                &::after {
                    content: url("./right-arrow.svg");
                    font-size: 30px;
                    padding-left: 10px;
                    margin-top: -2px;
                }

                &:hover,
                &:active:focus,
                &:active {
                    text-decoration: underline;
                }

                &:active,
                &:focus,
                &:focus-visible {
                    color: $black;
                }
            }

            &_mobile {
                display: none;
                padding: 0 $padding 70px;

                @media only screen and (max-width: 767px) {
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin-top: -40px;

                    a {
                        font-size: clamp(13px, .9vw, 16px);
                        font-weight: bold;
                        color: $black;
                        display: flex;
                        align-items: center;

                        &::after {
                            content: "\2192";
                            font-size: 30px;
                            color: transparent;
                            background-image: $dark-gradient;
                            background-clip: text;
                            padding-left: 10px;
                        }

                        &:hover,
                        &:active:focus,
                        &:active {
                            color: $primary;
                        }

                        &:active,
                        &:focus,
                        &:focus-visible {
                            color: $black;
                        }
                    }
                }
            }
        }
    }

    &__divider {
        border: none;
        height: 2px;
        background-color: #E6E6E6;
    }
}