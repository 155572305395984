@import "../../_globals/variables";

.complaints {
    padding: 120px $padding 0;
    margin-bottom: -150px;

    @media only screen and (max-width: 767px) {
        margin-bottom: -100px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: -100px;
    }

    p {
        font-size: clamp(16px, 1.15vw, 24px);
        margin: 20px 0;
        display: flex;
        align-items: center;
        font-weight: bold;

        a {
            margin-left: 20px;
        }
    }
}