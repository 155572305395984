@import "../../_globals/variables";

.cards {
    padding: 50px 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 1fr;
    grid-template-areas:
        ". . .";

    @media only screen and (max-width: 767px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-template-areas:
            ".";
        padding: 10px 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-areas:
            "..";
        padding: 20px 0;
    }

    &__wrapper {
        text-align: center;
        border-radius: 9px;
        padding: 30px;
        transition: transform .4s ease-out;

        @media only screen and (max-width: 767px) {
            padding: 30px 0;
        }

        &:hover {
            transform: translateY(-20px);
        }

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            margin-bottom: -10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &_content {
            background-color: $white;
            padding: 30px 20px;
            box-shadow: $box-shadow;
            min-height: 170px;

            h3 {
                text-transform: uppercase;
                line-height: 1.5;
            }

            p {
                margin: 15px 0 30px;
                color: $charcoal;
                line-height: 2.15;
            }

            img {
                width: 24px;
                height: 24px;
                object-fit: contain;
            }
        }
    }
}