@import "../../_globals/variables";

.token {
    &__details {
        padding: 100px $padding;

        @media only screen and (max-width: 767px) {
            padding: 70px $padding;
        }

        &_cards {
            display: grid;
            text-align: center;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: 1fr;
            gap: 50px;
            grid-template-areas:
                ". .";

            @media only screen and (max-width: 767px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                gap: 50px 0;
                grid-template-areas:
                    ".";
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                gap: 50px;
                grid-template-areas:
                    "..";
            }

            &_single {
                @media only screen and (max-width: 767px) {
                    display: flex;
                }

                article {
                    @media only screen and (max-width: 767px) {
                        width: 80%;
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    &__packages {
        h2 {
            max-width: 70%;
            margin: 30px auto 0;

            @media only screen and (max-width: 767px) {
                max-width: 75%;
            }
        }

        p {
            max-width: 90%;
            margin: 10px auto;

            @media only screen and (max-width: 767px) {
                max-width: 100%;
            }
        }
    }

    &__model {
        padding: 100px $padding;

        &_intro {
            margin-bottom: 100px;
        }

        &_bottom {
            h2 {
                text-align: center;
            }

            &_description {
                margin: 50px 0;
                font-weight: bold;
            }

            &_cards {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                grid-template-rows: 1fr;
                gap: 50px;
                grid-template-areas:
                    ". . . .";

                @media only screen and (max-width: 767px) {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    gap: 50px 0;
                    grid-template-areas:
                        ".";
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 50px;
                    grid-template-areas:
                        "..";
                }

                &_single {
                    @media only screen and (max-width: 767px) {
                        display: flex;
                    }

                    h2 {
                        font-size: 24px;
                        margin-bottom: 25px;
                        padding: 7px 5px 4px;
                        color: $white;
                        line-height: 1;
                        background-image: linear-gradient(127.45deg, #44DCB7 23.85%, #35E0F4 100%, rgba(68, 220, 183, 0) 100%);
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        object-fit: contain;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media only screen and (max-width: 767px) {
                            padding: 7px;
                            width: 40px;
                            height: 40px;
                        }
                    }

                    article {
                        @media only screen and (max-width: 767px) {
                            width: 80%;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
}