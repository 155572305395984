@import "../../_globals/variables";

.infocards {
    padding: 100px $padding;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 1fr;
    gap: 50px;
    grid-template-areas:
        ". . .";

    @media only screen and (max-width: 767px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 50px 0;
        grid-template-areas:
            ".";
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 50px;
        grid-template-areas:
            "..";
    }

    .true {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__wrapper {
        text-align: center;
        border-radius: 9px;
        padding: 30px;
        transition: transform .4s ease-out;
        box-shadow: $box-shadow;

        &_centered {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &:hover {
            transform: translateY(-20px);
        }

        img {
            width: 15vw;
            height: auto;
            object-fit: contain;

            @media only screen and (max-width: 767px) {
                width: 70vw;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 30vw;
            }
        }

        article {
            margin: 20px 0 30px;
        }

        div {
            margin-bottom: 30px;
        }
    }
}