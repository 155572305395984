@import "../../_globals/variables";

.posts {
    padding: 60px $padding 120px;

    @media only screen and (max-width: 767px) {
        padding: 30px $padding 70px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        padding: 50px $padding 100px;
    }

    .slick-next,
    .slick-prev {

        @media only screen and (max-width: 767px) {
            top: auto;
            bottom: -15px;
        }

        &::before {
            font-size: clamp(25px, 2vw, 45px);
            color: $charcoal;
            opacity: 1;
        }
    }

    .slick-prev {
        @media only screen and (max-width: 767px) {
            left: 40%;
        }

        &::before {
            content: url("./carousel-prev.svg");
        }
    }

    .slick-next {
        @media only screen and (max-width: 767px) {
            right: 40%;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            right: -35px;
        }

        &::before {
            content: url("./carousel-next.svg");
        }
    }

    .slick-dots li button:before,
    .slick-next:before,
    .slick-prev:before {
        font-family: inherit;
    }

    &__wrapper {
        max-width: 90%;
        margin: 0 auto 50px 25px;
        box-shadow: $box-shadow;
        border-radius: 5px;
        background-color: $white;
        transition: transform .4s ease-out;

        &:hover {
            transform: translateY(-20px);
        }

        @media only screen and (max-width: 767px) {
            margin: 0 auto 50px 20px;
            max-width: 90%;
        }

        img {
            width: 100%;
            height: 180px;
            object-fit: cover;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &_content {
            padding: 30px 30px 50px;
            min-height: 280px;

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                min-height: 300px;
            }

            h5 {
                margin-bottom: 20px;
                color: #7f7f7f;
                text-transform: uppercase;
            }

            h4 {
                letter-spacing: normal;
            }

            p {

                margin: 20px 0;
            }

            &_link {
                font-size: clamp(15px, .9vw, 16px);
                font-weight: 600;
                line-height: 1.48;
                display: inline-block;
                border-radius: 40px;
                padding: 13px 22px;
                color: $charcoal;
                transition: all .3s;
                position: relative;
                overflow: hidden;
                z-index: 1;
                border: 2px solid transparent;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: $light-gradient;
                    border-radius: 40px;
                    z-index: -2;
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 100%;
                    background: $white;
                    transition: all .3s;
                    border-radius: 40px;
                    z-index: -1;
                }

                &:hover,
                &:active:focus,
                &:active {
                    color: $charcoal;
                    border: 2px solid $charcoal;
                    box-shadow: none;

                    &:before {
                        width: 100%;
                    }
                }

                &:focus {
                    box-shadow: none;
                    color: $charcoal;

                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }
}