/* colors */
$primary: #16359D;
$secondary: #73D9B9;
$accent: #65CBE8;
$link: #1132A3;
$white: #FFF;
$black: #000;
$charcoal: #1A1A1A;

$dark-gradient: linear-gradient(89.97deg, #35E0F4 -11.47%, #33D7F0 -3.71%, #2EBDE4 9.2%, #2594D0 25.64%, #195BB6 44.81%, #1132A3 57.23%, #1132A3 99.98%);
$light-gradient: linear-gradient(180deg, #FFFFFF 0%, #44DCB7 342.37%);

$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

/* Fluid Layout */
$padding: clamp(30px, 9vw, 250px);