@import "../../_globals/variables";

.technology {
    &__hero_full {
        width: 100%;
        height: 100%;
        margin: 20px 0 40px;

        @media only screen and (max-width: 767px) {
            max-width: 100%;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            max-width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
        }

        p {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__hero {
        padding: 50px 30px 50px 30px;
        // min-height: 90vh;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        position: relative;

        @media only screen and (max-width: 767px) {
            flex-direction: column-reverse;
            // padding: 30px 0;
            padding: 60px 30px 0px 30px;
            align-items: flex-start;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            min-height: 70vh;
        }

        @media only screen and (min-width: 1024px) {
            min-height: 90vh; 
        }

        &_button {
            margin-top: 15px;
        }

        &_gradient {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            height: 200px;

            @media only screen and (max-width: 767px) {
                height: 100px;
            }
        }

        &_left {
            width: 45%;

            @media only screen and (max-width: 767px) {
                width: auto;
                padding: 20px $padding 30px;
            }

            h1 {
                max-width: 80%;

                @media only screen and (max-width: 767px) {
                    max-width: 100%;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    max-width: 100%;
                }
            }

            &_content {
                margin: 20px 0 40px;
                max-width: 85%;

                @media only screen and (max-width: 767px) {
                    max-width: 100%;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    max-width: 100%;
                }

                p {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            small {
                display: block;
                font-size: clamp(14px, 1vw, 16px);
                margin: 30px 0 20px;
            }
        }

        &_right {
            width: 55%;
            margin: 50px 0;
            padding-right: 50px;

            @media only screen and (max-width: 767px) {
                width: 100%;
                padding-right: 0;
                margin: 50px 0 0;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                padding: 0 30px;
            }

            &_lottie {
                width: 100%;
                height: 100%;

                @media only screen and (max-width: 767px) {
                    display: none;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    display: none;
                }

                &_mobile {
                    display: none;

                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }
        }
    }

    &__security {
        padding: 100px $padding 0;
        background-color: #f1f1f1;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }

        &_wrapper {
            display: flex;
            justify-content: space-between;
            max-width: 80%;
            margin: 0 auto;

            @media only screen and (max-width: 767px) {
                flex-direction: column;
                max-width: 100%;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                max-width: 100%;
            }
        }

        &_left {
            width: 50%;
            margin-bottom: 100px;

            @media only screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
                margin-bottom: 50px;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 50%;
            }

            p {
                margin: 20px 0 30px;
            }
        }

        &_right {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 40%;

            @media only screen and (max-width: 767px) {
                width: 100%;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 50%;
            }

            img {
                width: auto;
                height: 30vw;
                object-fit: contain;

                @media only screen and (max-width: 767px) {
                    height: 300px;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    margin-left: 50px;
                    height: 300px;
                }
            }
        }
    }
}