@import "../../_globals/variables";

.footer {
    background-color: $primary;
    color: #F1F1F1;
    padding: 60px $padding 40px;

    a {
        color: #F1F1F1;
        transition: 0.3s;

        &:hover {
            color: #F1F1F1;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    &__top {
        background-color: $primary;
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-template-rows: 1fr;
        gap: 50px;
        grid-template-areas:
            ". . . .";

        @media only screen and (max-width: 767px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 50px 0;
            grid-template-areas:
                ".";
        }


        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: 1fr;
            gap: 50px 0;
            grid-template-areas:
                ". .";
        }

        h5 {
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        &_links {
            margin-bottom: 5px;
        }

        &_logo {
            img {
                height: 25px;
                width: auto;
                object-fit: contain;
            }
        }
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;

        img {
            width: 25px;
            height: 25px;
            object-fit: contain;
            margin-right: 50px;
            margin-bottom: 10px;

            @media only screen and (max-width: 767px) {
                margin-right: 30px;
            }
        }
    }
}