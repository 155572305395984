@import "../../_globals/variables";

.about {
    &__hero {
        padding: 50px 0 50px $padding;
        min-height: 90vh;
        display: flex;
        align-items: center;
        background-color: #f1f1f1;
        position: relative;

        @media only screen and (max-width: 767px) {
            flex-direction: column-reverse;
            padding: 10px 0;
            align-items: flex-start;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            min-height: 70vh;
        }

        &_gradient {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            height: 300px;

            @media only screen and (max-width: 767px) {
                height: 250px;
            }
        }

        &_left {
            width: 45%;

            @media only screen and (max-width: 767px) {
                width: auto;
                padding: 50px $padding;
            }

            h1 {
                max-width: 80%;

                @media only screen and (max-width: 767px) {
                    max-width: 100%;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    max-width: 100%;
                }
            }

            &_content {
                margin: 20px 0 40px;
                max-width: 85%;

                @media only screen and (max-width: 767px) {
                    max-width: 100%;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    max-width: 100%;
                }

                p {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            small {
                display: block;
                font-size: clamp(14px, 1vw, 16px);
                margin: 30px 0 20px;
            }
        }

        &_right {
            width: 55%;
            margin-left: auto;

            @media only screen and (max-width: 767px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                margin-left: 30px;
            }

            &_principles {
                margin-top: 50px;

                @media only screen and (max-width: 767px) {
                    margin-left: $padding;
                }

                &_lottie {
                    width: 100%;
                    height: 100%;

                    @media only screen and (max-width: 767px) {
                        display: none;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        display: none;
                    }

                    &_mobile {
                        display: none;

                        @media only screen and (max-width: 767px) {
                            width: 100vw;
                            height: auto;
                            display: block;
                        }

                        @media only screen and (min-width: 768px) and (max-width: 1024px) {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }
                }

                h3 {
                    margin-bottom: 30px;
                }

                &_card {
                    display: flex;
                    align-items: center;
                    padding: 20px 25px;
                    background-color: $white;
                    margin-bottom: 30px;

                    h4 {
                        font-size: clamp(18px, 1.5vw, 35px);
                        padding: 7px 5px 4px;
                        color: $white;
                        line-height: 1.5;
                        background-image: linear-gradient(127.45deg, #44DCB7 23.85%, #35E0F4 100%, rgba(68, 220, 183, 0) 100%);
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        object-fit: contain;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 20px;

                        @media only screen and (max-width: 767px) {
                            padding: 7px;
                            width: 30px;
                            height: 30px;
                        }

                        @media only screen and (min-width: 768px) and (max-width: 1024px) {
                            width: 30px;
                            height: 30px;
                        }

                    }

                    h2 {
                        font-size: clamp(18px, 1.5vw, 35px);
                        line-height: 1.5;
                    }
                }
            }

            &_download {
                border-top-left-radius: 100px;
                border-bottom-left-radius: 100px;
                background: linear-gradient(to right, #44dcb7, #35e0f4, rgba(68, 220, 183, 1));
                display: flex;
                align-items: center;
                padding: 20px 50px;
                margin-top: 60px;
                z-index: 0;
                position: relative;
                overflow: hidden;

                &::after {
                    content: '';
                    top: 0;
                    transform: translateX(100%);
                    width: 100%;
                    height: 220px;
                    position: absolute;
                    z-index: 1;
                    animation: slide 1s infinite;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
                }

                @keyframes slide {
                    0% {
                        transform: translateX(-100%);
                    }

                    100% {
                        transform: translateX(100%);
                    }
                }

                @media only screen and (max-width: 767px) {
                    border-radius: 0;
                    padding: 10px 0;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    flex-wrap: wrap;
                    margin-top: 20px;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    flex-wrap: wrap;
                    padding: 10px 0;
                }

                h4 {
                    margin-right: 50px;
                    text-transform: uppercase;

                    @media only screen and (max-width: 767px) {
                        margin-right: 20px;
                        text-align: center;
                        font-size: 4vw;
                        letter-spacing: 1px;
                    }

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        margin-right: 20px;
                        text-align: center;
                        letter-spacing: 1px;
                        font-size: 2vw;
                    }
                }

                &_icons {
                    a {
                        display: inline;
                    }

                    img {
                        width: 30px;
                        height: 35px;
                        margin-right: 30px;
                        object-fit: contain;

                        @media only screen and (max-width: 767px) {
                            width: 20px;
                            height: 25px;
                            margin-right: 20px;
                        }

                        @media only screen and (min-width: 768px) and (max-width: 1024px) {
                            width: 20px;
                            height: 25px;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

    &__team {
        padding: 20px $padding 0;
        background-color: #f1f1f1;

        @media only screen and (max-width: 767px) {
            padding: 0 $padding 50px;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            padding: 0 $padding 50px;
        }

        h2 {
            text-align: center;
        }
    }

    &__partners {
        padding: 150px $padding 50px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
    }

    &__socials {
        padding: 0 $padding 50px;

        h1 {
            text-align: center;

            @media only screen and (max-width: 767px) {
                text-align: center;
                max-width: 75%;
                margin: 0 auto;
            }
        }
    }

    &__twitter {
        padding: 0 $padding;
        display: flex;
        justify-content: space-between;
        margin: 0 25px;

        &_icon {
            width: 35px;
            height: 35px;
            object-fit: contain;
        }

        &_btn {

            a {
                font-size: clamp(13px, .9vw, 16px);
                font-weight: bold;
                color: $black;
                display: flex;
                align-items: center;

                &::after {
                    content: url("./right-arrow.svg");
                    font-size: 30px;
                    padding-left: 10px;
                    margin-top: -2px;
                }

                &:hover,
                &:active:focus,
                &:active {
                    text-decoration: underline;
                }

                &:active,
                &:focus,
                &:focus-visible {
                    color: $black;
                }
            }
        }
    }

    &__medium {
        padding: 0 $padding;
        display: flex;
        justify-content: space-between;
        margin: 0 25px;

        &_icon {
            width: 35px;
            height: 35px;
            object-fit: contain;
        }

        &_btn {

            a {
                font-size: clamp(13px, .9vw, 16px);
                font-weight: bold;
                color: $black;
                display: flex;
                align-items: center;

                &::after {
                    content: url("./right-arrow.svg");
                    font-size: 30px;
                    padding-left: 10px;
                    margin-top: -2px;
                }

                &:hover,
                &:active:focus,
                &:active {
                    text-decoration: underline;
                }

                &:active,
                &:focus,
                &:focus-visible {
                    color: $black;
                }
            }
        }
    }
}