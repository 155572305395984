@import "../../_globals/variables";

.partners {
    text-align: center;
    margin-bottom: 100px;

    @media only screen and (max-width: 767px) {
        max-width: 100%;
        margin-bottom: 50px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
    }

    p {
        margin: 30px 0 40px;
    }

    &__logos {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-template-rows: 1fr;
        gap: 50px;
        grid-template-areas:
            ". . . .";
        margin: 50px auto;
        max-width: 1000px;
        justify-items: center;

        @media only screen and (max-width: 767px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-areas:
                ". .";
            gap: 30px 20px;
            margin: 50px 20px;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-template-areas:
                ". . . .";
            gap: 30px 20px;
            margin: 50px 20px;
        }

        img {
            width: auto;
            height: 120px;
            max-width: 150px;
            object-fit: contain;
            filter: grayscale(1);

            @media only screen and (max-width: 767px) {
                max-width: 100px;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                max-width: 100px;
            }
        }
    }
}