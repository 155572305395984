* {box-sizing: border-box;}
html, body {overflow-x: hidden;}
.scrollArrow {width: 35px;display: grid;align-content: center;height: 30px;margin-top: 60px;margin-left: 2px;}
.scrollArrow img {cursor: pointer;height: 11px;animation: scrollzoom 2s infinite}
@keyframes scrollzoom {
    0%, 100% {
        height: 11px;
    }

    50% {
        height: 13px;
    }
}

.mainH1 {font-size: 42px;line-height: 63px;}
.mainH2 {font-size: 32px;line-height: 48px;}

.mainContainer {width: 100%;margin: 0 auto;padding: 0px 30px;max-width: 89%;}
.mainHero .mainContent {width: 100%;display: flex;flex-wrap: wrap;padding-top: 133px;}
.mainHero .mainContent .division {width: 100%;float: left;}
.mainHero .mainContent .division#left {max-width: 46%;display: grid;align-content: center;align-items: center;}
.mainHero .mainContent .division#left .divisionIn {padding-right: 54px;padding-right: 0px;}
.mainHero .mainContent .division#right {max-width: 54%;}
.mainHero .mainContent .division#right .divisionIn {}
.mainHero .mainContent .division#right .divisionIn > img {margin-right: -39%;float: right;width: 1000px;}
.mainHero .mainP {margin-top: 8px;}
.mainHero .actions {margin-top: 25px;}
.mainHero .actions a {margin-right: 22px;}

.downloadApp {background: linear-gradient(127.45deg, #44DCB7 23.85%, #35E0F4 96.39%, rgba(68, 220, 183, 0) 96.39%);border-radius: 100px;width: 1700px;position: relative;overflow: hidden;margin-left: 75px;}
.downloadApp::after {content: '';top: 0;transform: translateX(100%);width: 100%;height: 220px;position: absolute;z-index: 1;animation: slide 5s infinite;background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);pointer-events: none;}
.downloadAppIn {display: flex;padding: 11px 10px 11px 48px;}
.downloadAppIn span {font-weight: 700;font-size: 20px;letter-spacing: 4px;color: #1a1a1a;text-transform: uppercase;display: grid;align-content: center;padding-top: 2px;}
.downloadAppIn .appIcons {margin-left: 49px;}
.downloadAppIn .appIconsIn {}
.downloadAppIn .appIcons a {display: inline-grid;margin-right: 32px;height: 100%;align-content: center;}
.downloadAppIn .appIcons a img {height: 41px;width: auto;}
.ctaBlue {font-size: clamp(15px,.9vw,16px);font-weight: 600;line-height: 1.48;display: inline-block;border-radius: 40px;padding: 13px 22px;color: #fff;transition: all .3s;position: relative;overflow: hidden;z-index: 1;border: 2px solid transparent;font-family: inherit;cursor: pointer;}
.ctaBlue::before {content: "";position: absolute;bottom: 0;left: 0;height: 100%;border-radius: 40px;width: 0;background: #fff;transition: all .3s;z-index: -1;pointer-events: none;}
.ctaBlue::after {content: "";position: absolute;bottom: 0;left: 0;height: 100%;border-radius: 40px;width: 0;background: #fff;transition: all .3s;z-index: -1;width: 100%;background: linear-gradient(89.97deg,#35e0f4 -11.47%,#33d7f0 -3.71%,#2ebde4 9.2%,#2594d0 25.64%,#195bb6 44.81%,#1132a3 57.23%,#1132a3 99.98%);z-index: -2;pointer-events: none;}
.ctaBlue:hover {color: #16359d;border: 2px solid #16359d;box-shadow: none;}
.ctaBlue:hover::before {width: 100%;}
.ctaGreen {font-size: clamp(15px,.9vw,16px);font-weight: 600;line-height: 1.48;display: inline-block;border-radius: 40px;padding: 13px 22px;color: #1a1a1a;transition: all .3s;position: relative;overflow: hidden;z-index: 1;border: 2px solid transparent;}
.ctaGreen::before {content: "";position: absolute;bottom: 0;left: 0;width: 0;height: 100%;background: #fff;transition: all .3s;border-radius: 40px;z-index: -1;pointer-events: none;}
.ctaGreen::after {content: "";position: absolute;bottom: 0;left: 0;width: 100%;height: 100%;background: linear-gradient(180deg,#fff,#44dcb7 342.37%);border-radius: 40px;z-index: -2;}
.ctaGreen:hover {color: #1a1a1a;border: 2px solid #1a1a1a;box-shadow: none;}
.ctaGreen:hover::before {width: 100%;}
@keyframes slide {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.allFeatures {display: flex;flex-wrap: wrap;}
.allFeatures .featureSingle {width: 100%;max-width: calc(100%/3);margin: 60px 0px 0px 0px;}
.allFeatures .featureSingle:nth-child(1), .allFeatures .featureSingle:nth-child(2), .allFeatures .featureSingle:nth-child(3) {margin-top: 0px;}
.allFeatures .featureSingleIn {height: 100%;display: grid;align-content: space-between;}
.allFeatures .featureSingleIn .featureSingleHead {}
.allFeatures .featureSingle:nth-child(1) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(4) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(7) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(10) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(13) .featureSingleIn {padding-right: 70px;}
.allFeatures .featureSingle:nth-child(2) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(5) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(8) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(11) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(14) .featureSingleIn {padding-left: 35px;padding-right: 35px;}
.allFeatures .featureSingle:nth-child(3) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(6) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(9) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(12) .featureSingleIn, 
.allFeatures .featureSingle:nth-child(15) .featureSingleIn {padding-left: 70px;padding-right: 0px;}
.allFeatures .featureSingle #icon {width: auto;height: 65px;}
.allFeatures .featureSingle h3 {margin-top: 20px;font-size: 16px;line-height: 24px;}
.allFeatures .featureSingle .mainP {margin-top: 43px;}
.allFeatures .featureSingle .ctaGreen {margin-top: 20px;}

.newsletter {background: linear-gradient(227.78deg, #FFFFFF -16.46%, #44DCB7 189.09%);box-shadow: 0px 0px 15px rgba(96, 96, 96, 0.2);border-radius: 9px;padding: 77px 30px 69px 30px;margin-bottom: 20px;}
.newsletter .newsletterIn {max-width: 575px;margin: 0 auto;}
.newsletter .newsletterIn h3 {font-size: 16px;line-height: 24px;text-align: center;}
.newsletter .newsletterIn .mainP {text-align: center;margin-top: 36px;}
.newsletter .newsletterIn form {width: 100%;margin: 42px auto 0px auto;max-width: 465px;}
.newsletter .newsletterIn .formField {position: relative;}
.newsletter .newsletterIn .formField input {background: #FFFFFF;border-radius: 40px;border: 2px solid #fff;padding: 10px 150px 10px 20px;box-shadow: unset !important;outline: none;font-family: 'Spartan', sans-serif;font-size: 15px;width: 100%;color: #373737;}
.newsletter .newsletterIn .formField button {border-width: 1px;font-size: 15.5px;position: absolute;right: 0;padding: 0px 25px !important;height: 100%;}
.newsletter p#error, .newsletter p#sent {display: none;}
.newsletter p#error {text-align: center;color: #f52d2d;margin-top: 10px;display: none;}
.newsletter p#sent {text-align: center;color: #219b40;margin-top: 10px;display: none;}
.newsletter[data-status="invalid"] p#error {display: block;}
.newsletter[data-status="success"] p#sent {display: block;}
.newsletter[data-status="sending"] form {opacity: 0.6;pointer-events: none;}
.newsletter[data-status="success"] .formField {display: none;}

.slick-slider img#slickPrev {cursor: pointer;width: 100%;max-width: 20px;height: auto;position: absolute;top: 50%;transform: translateY(-50%);left: -40px;}
.slick-slider img#slickNext {cursor: pointer;width: 100%;max-width: 20px;height: auto;position: absolute;top: 50%;transform: translateY(-50%);right: -40px;}

@media screen and (max-width: 1600px) {
    .mainHero .mainContent .division#right .divisionIn > img {width: 1000px;margin-right: -50.6%}
}

@media screen and (max-width: 1390px) {
    .mainHero .mainContent .division#right .divisionIn > img {width: 860px;margin-right: -50.6%;}
}

@media screen and (max-width: 1300px) {
    .mainHero .mainContent .division#right .divisionIn > img {width: 725px;margin-right: -47.6%;}
}

@media screen and (max-width: 1200px) {
    .mainH1 {font-size: 28px !important;line-height: 40px !important;}
    .mainH2 {font-size: 28px !important;line-height: 40px !important;}
    .scrollArrow {display: none;}
    .mainContainer {max-width: 100%;padding: 0px 35px;}
    .downloadApp {border-radius: 0px;background: linear-gradient(127.45deg, #44DCB7 23.85%, #35E0F4 100%, rgba(68, 220, 183, 0) 96.39%) !important;margin-left: -50vw !important;margin-right: -50vw !important;width: 100vw;position: relative;left: 50%;right: 50%;margin-top: 40px !important;} 
    .downloadAppIn {padding: 11px 30px 11px 30px;justify-content: center;}
    .downloadAppIn span {font-size: 17.7px;letter-spacing: 2.13px;}
    .downloadAppIn .appIcons {margin-left: 15px;}
    .downloadAppIn .appIcons a {margin-right: 18px;}
    .downloadAppIn .appIcons a:last-child {margin-right: 0px;}
    .downloadAppIn .appIcons a img {height: 26px;}
    .downloadAppIn .appIcons a:nth-child(1) img {height: 23px !important;}
    .downloadAppIn .appIcons a:nth-child(3) img {height: 21px !important;}
    .mainHero {padding-top: 100px;}
    .mainHero .mainContent {flex-direction: column-reverse;padding-top: 0px;}
    .mainHero .mainContent .division#right .divisionIn > img {margin: 0px auto;width: 100%;max-width: 1000px;display: block;float: none;}
    .mainHero .mainContent .division {max-width: 100% !important;float: none;}    
    .mainHero .mainContent .division#left .divisionIn {padding-right: 0px;}
    .mainHero .mainContent .division#left {padding-top: 45px;}
}

@media screen and (max-width: 991px) {
    .allFeatures .featureSingle {width: 100%;max-width: calc(100%/2);margin: 60px 0px 0px 0px !important;}
    .allFeatures .featureSingleIn {padding: 0px !important;}
    .allFeatures .featureSingle:nth-child(odd) .featureSingleIn {padding-right: 40px !important;}
    .allFeatures .featureSingle:nth-child(even) .featureSingleIn {padding-left: 40px !important;}
}

@media screen and (max-width: 767px) {
    .allFeatures {margin-top: -7px !important;}
    .allFeatures .featureSingle {max-width: calc(100%/1);margin: 40px 0px 0px 0px !important;}
    .allFeatures .featureSingle:first-child {margin-top: 0px;}
    .allFeatures .featureSingle:nth-child(odd) .featureSingleIn, .allFeatures .featureSingle:nth-child(even) .featureSingleIn {padding: 0px !important}
    .allFeatures .featureSingle h3 {margin-top: 15px;}
    .allFeatures .featureSingle .mainP {margin-top: 13px;}
    .allFeatures .featureSingle .ctaGreen {margin-top: 16px;}
}

@media screen and (max-width: 450px) {
    .downloadAppIn {padding: 8px 20px 9px 20px}
    .downloadAppIn span {font-size: 4vw;}
    .downloadAppIn .appIconsIn {padding-top: 4px;}
    .downloadAppIn .appIcons a img {height: 24px;}
    .downloadAppIn .appIcons a:nth-child(1) img {height: 21px !important;}
    .downloadAppIn .appIcons a:nth-child(3) img {height: 19px !important;}
    .newsletter .newsletterIn .formField input {padding: 10px 20px 10px 20px;}
    .newsletter .newsletterIn .formField button {position: initial;margin: 5px auto 0px auto;display: block;width: 100%;position: relative !important;padding: 10px 20px !important;}
}