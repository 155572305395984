@import "../../_globals/variables";

.content {
    padding: 100px $padding 150px;

    @media only screen and (max-width: 767px) {
        padding: 50px $padding 100px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        padding: 50px $padding 100px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 50px 0 20px;
    }

    p {
        line-height: 32px;
    }

    a {
        text-decoration: underline;
        font-weight: bold;
    }
}