@import "../../_globals/variables";

.subscription {
    padding: 0 $padding 50px;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #44DCB7 200%);

    &__inner {
        text-align: center;
        max-width: 75%;
        margin: 0 auto;

        @media only screen and (max-width: 767px) {
            max-width: 100%;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            max-width: 100%;
        }

        h2 {
            text-transform: capitalize;
        }

        article {
            margin: 30px 0 40px;
        }

        &_socials {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 80px 0 50px;

            @media only screen and (max-width: 767px) {
                flex-direction: column;
                margin: 40px 0 20px;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                margin: 50px 0 20px;
            }

            &_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                @media only screen and (max-width: 767px) {
                    margin: 20px 0;
                }

                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    flex-direction: column;
                }

                img {
                    margin-right: 10px;
                    width: 35px;
                    height: auto;
                    object-fit: contain;

                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        margin-right: 0;
                        margin-bottom: 20px;
                    }
                }

                a {
                    margin: 0;
                    text-transform: uppercase;
                    color: #7f7f7f;
                    font-weight: 500;
                    letter-spacing: 4px;
                }
            }
        }
    }
}