@import "../../_globals/variables";

.technologies {
    padding: 50px $padding;

    &__wrapper {
        margin: 30px auto;
        border-bottom: 1px solid #a1a1a1;
        padding-bottom: 50px;
        max-width: 80%;

        &:last-child {
            border-bottom: none;
        }

        @media only screen and (max-width: 767px) {
            max-width: 100%;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            max-width: 100%;
        }

        img {
            height: 100px;
            width: auto;
            object-fit: contain;
        }

        &_lottie {
            height: 100px;
            width: 120px;
            object-fit: contain;
            margin-left: -20px;
        }

        h2 {
            margin: 10px 0 20px;
        }

        article {

            a {
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }
}