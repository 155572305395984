@import "../../_globals/variables";

.featured {
    max-width: 70%;
    margin: 0 auto;
    text-align: center;

    @media only screen and (max-width: 767px) {
        max-width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
    }

    article {
        margin: 30px 0 40px;
    }

    &__logos {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-template-rows: 1fr;
        gap: 50px;
        grid-template-areas:
            ". . . .";
        margin: 50px;

        @media only screen and (max-width: 767px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-areas:
                ". .";
            margin-left: 20px;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-template-areas:
                ". . .";
        }

        img {
            width: 120px;
            height: auto;
            object-fit: contain;

            @media only screen and (max-width: 767px) {
                width: 100px;
            }
        }
    }
}