@import "../../../_globals/variables";

.headerLinks {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media only screen and (max-width: 768px) {
        display: block;
    }

    &__items {
        display: flex;

        @media only screen and (max-width: 768px) {
            display: block;
        }

        &__menu, a,
        &:visited {
            color: $black;
            margin-right: 50px;
            font-size: clamp(14px, 1vw, 16px);
            font-weight: bold;
            line-height: 1.48;

            @media only screen and (max-width: 768px) {
                color: $black;
                width: calc(100% - 30px);
                margin-top: 8px;
                text-align: left;
                margin-left: 15px;
                margin-bottom: 8px;
                margin-right: 0;
                border: none;
            }

            &:hover {
                color: $primary;
            }

            &::after {
                @media only screen and (max-width: 768px) {
                    content: "";
                    display: block;
                    margin: 10px 15px;
                }
            }
        }

        &__sub_menu {
            padding: 5px;
            font-family: 'Spartan', sans-serif;
        }
    }

    &__btn {
        display: flex;
        margin-left: auto;

        @media only screen and (max-width: 767px) {
            margin-top: 20px;
            margin-bottom: 30px;
            margin-left: 10px;
            display: block;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            margin-top: 20px;
            margin-bottom: 30px;
            margin-left: 10px;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;
        }

        div {

            @media only screen and (max-width: 767px) {
                &:last-child {
                    a {
                        margin-top: 0;
                    }
                }
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                margin-right: 20px;
            }
        }

        a {
            margin-left: 0 !important;

            @media only screen and (max-width: 768px) {
                display: inline-block;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        :last-of-type {
            margin-left: 25px;

            @media only screen and (max-width: 768px) {
                margin-left: 0;
            }
        }
    }

    &__menuActive {
        color: $primary !important;
        position: relative;

        &::after {
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: -10px;
            content: "";
            display: flex;
            background-image: $dark-gradient;

            @media only screen and (max-width: 768px) {
                position: relative;
                width: 0;
                display: block;
                bottom: 0;
                margin: 0 15px 10px !important;
            }
        }
    }

    &__social {
        display: none;

        @media only screen and (max-width: 768px) {
            padding: 0 15px 0 10px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;

            img {
                width: 25px;
                height: 25px;
                object-fit: contain;
                margin-right: 50px;
                margin-bottom: 10px;

                @media only screen and (max-width: 767px) {
                    margin-right: 30px;
                }
            }
        }

    }

    &__subMenu {
        color: black;
     }
}