@import "../../_globals/variables";

.popup {

    button {
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        font-family: 'Spartan', sans-serif;
        font-size: clamp(14px, 1vw, 16px);
        line-height: 28px;
        color: #F1F1F1;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
            color: #F1F1F1;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    &_ico {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 30px;
        width: auto;
        margin-bottom: 20px;
    }

    &_btns {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &_cancel {
            margin-right: 20px;

            a {
                font-size: clamp(15px, .9vw, 16px);
                font-weight: bold;
                color: $black;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover,
                &:active:focus,
                &:active {
                    text-decoration: underline;
                }

                &:focus,
                &:focus-visible {
                    color: $black;
                }
            }
        }

        &_proceed {
            a {
                font-size: clamp(15px, .9vw, 16px);
                font-weight: bold;
                color: $black;
                display: flex;
                align-items: center;

                &:hover,
                &:active:focus,
                &:active {
                    text-decoration: underline;
                }

                &:focus,
                &:focus-visible {
                    color: $black;
                }
            }
        }
    }
}