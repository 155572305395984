@import "../../_globals/variables";

.btn {
    &__primary {
        font-size: clamp(15px, .9vw, 16px);
        font-weight: 600;
        line-height: 1.48;
        display: inline-block;
        border-radius: 40px;
        padding: 13px 22px;
        color: $white;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: 2px solid transparent;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $dark-gradient;
            border-radius: 40px;
            z-index: -2;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background: $white;
            transition: all .3s;
            border-radius: 40px;
            z-index: -1;
        }

        &:hover,
        &:active:focus,
        &:focus,
        &:active {
            color: $primary;
            border: 2px solid $primary;
            box-shadow: none;

            &:before {
                width: 100%;
            }
        }
    }

    &__secondary {
        font-size: clamp(15px, .9vw, 16px);
        font-weight: 600;
        line-height: 1.48;
        display: inline-block;
        border-radius: 40px;
        padding: 13px 22px;
        color: $charcoal;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: 2px solid transparent;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $light-gradient;
            border-radius: 40px;
            z-index: -2;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background: $white;
            transition: all .3s;
            border-radius: 40px;
            z-index: -1;
        }

        &:hover,
        &:active:focus,
        &:focus,
        &:active {
            color: $charcoal;
            border: 2px solid $charcoal;
            box-shadow: none;

            &:before {
                width: 100%;
            }
        }
    }

    &__tertiary {
        font-size: clamp(15px, .9vw, 16px);
        font-weight: 600;
        line-height: 1.48;
        display: inline-block;
        border-radius: 40px;
        padding: 13px 22px;
        color: $primary;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $white;
            border-radius: 40px;
            z-index: -2;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background: $dark-gradient;
            transition: all .3s;
            border-radius: 40px;
            z-index: -1;
        }

        &:hover,
        &:active:focus,
        &:focus,
        &:active {
            color: $white;
            box-shadow: none;

            @media only screen and (max-width: 767px) {
                background: $white;
            }

            &:before {
                width: 100%;
            }
        }
    }

    &__arrow {
        font-size: clamp(15px, .9vw, 16px);
        font-weight: bold;
        color: $black;
        display: flex;
        align-items: center;

        &::after {
            content: url("./right-arrow.svg");
            font-size: 30px;
            padding-left: 10px;
            margin-top: -2px;
        }

        &:hover,
        &:active:focus,
        &:active {
            text-decoration: underline;
        }

        &:focus,
        &:focus-visible {
            color: $black;
        }
    }

    &__case {
        font-size: clamp(15px, .9vw, 16px);
        font-weight: 600;
        line-height: 1.48;
        display: inline-block;
        border-radius: 40px;
        padding: 13px 22px;
        color: black;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $white;
            border-radius: 40px;
            z-index: -2;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background: $dark-gradient;
            transition: all .3s;
            border-radius: 40px;
            z-index: -1;
        }

        &:hover,
        &:active:focus,
        &:focus,
        &:active {
            color: $white;
            box-shadow: none;

            @media only screen and (max-width: 767px) {
                background: $white;
            }

            &:before {
                width: 100%;
            }
        }
    }
}