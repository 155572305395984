
/* WORDS SWAP */
[data-words]{vertical-align: top;position: static;}
[data-words] > span{position: absolute;}
.paymentsPage {background: rgb(124,228,202);background: linear-gradient( 223deg, rgb(124, 228, 202) 0%, rgb(241, 241, 241) 12%)}
.paymentsPage .mainHeroSecondShadow {background: rgb(241,241,241);background: linear-gradient(152deg,transparent 41%, rgb(183, 233, 228) 99%);}
.paymentsPage .mainHero {padding-top: 30px;}
.paymentsPage .mainHero h1 {max-width: 600px;}
.paymentsPage .mainHero .mainContent .division#right .divisionIn > img {margin-right: -23%;float: right;width: 900px;}
.paymentsPage .mainHero .mainContent .division#left .divisionIn {padding-top: 65px;}
.paymentsPage .mainHero .mainP {margin-top: 19px;}
.paymentsPage .mainHero .actions {margin-top: 28px;}
.paymentsPage .scrollArrow {margin-left: 4px;margin-top: 75px;}
.paymentsPage .mainIntegrate {padding-top: 175px;background: rgb(183,233,228);background: linear-gradient(212deg, rgb(183, 233, 228) -1%, rgb(241, 241, 241) 74%);}
.paymentsPage .mainIntegrate .mainContent {display: flex;flex-wrap: wrap;}
.paymentsPage .mainIntegrate .mainContent .division {width: 100%;max-width: calc(100%/2);float: left;}
.paymentsPage .mainIntegrate .mainContent .division#left {display: grid;align-content: center;}
.paymentsPage .mainIntegrate .mainContent .division#left .divisionIn {}
.paymentsPage .mainIntegrate .mainContent .division#left img {width: 100%;max-width: 595px;}
.paymentsPage .mainIntegrate .mainContent .division#right .icons {margin-bottom: 25px;}
.paymentsPage .mainIntegrate .mainContent .division#right .icons img {height: 23px;width: auto;margin-right: 28px;}
.paymentsPage .mainIntegrate .mainContent .division#right .icons img#android {height: 25px;margin-bottom: -2px;}
.paymentsPage .mainIntegrate .mainContent .division#right .icons img#code {height: 22px;}
.paymentsPage .mainIntegrate .mainContent .division#right .divisionIn {margin-right: 0;margin-left: auto;}
.paymentsPage .mainIntegrate .mainContent .division#right h2 {max-width: 410px;}
.paymentsPage .mainIntegrate .mainContent .division#right .mainP {margin-top: 13px}
.paymentsPage .mainIntegrate .mainContent .division#right .ctaBlue {margin-top: 30px;}
.paymentsPage .mainTransaction {background: rgb(183,233,228);background: linear-gradient(212deg, rgb(183, 233, 228) -29%, rgb(241, 241, 241) 36%);padding-top: 210px;}
.paymentsPage .mainTransaction .mainContainer {max-width: 1220px;}
.paymentsPage .mainTransaction h2 {text-align: center;display: block;margin: 0 auto;max-width: 810px;}
.paymentsPage .mainTransaction .allFeatures {margin-top: 85px;}
.paymentsPage .mainTransaction .allFeatures .featureSingle h3 {max-width: 209px;}
.paymentsPage .mainTransaction .allFeatures .featureSingle .mainP {margin-top: 21px;}
.paymentsPage .mainNewsletter {padding-top: 199px;background-color: #f1f1f1;}
.paymentsPage .mainNewsletter .mainContainer {max-width: 1220px;}
.paymentsPage .mainFlexible {padding-top: 101px;background: rgb(241,241,241);background: linear-gradient(204deg, rgb(241, 241, 241) 45%, rgb(183, 233, 228) 100%);padding-bottom: 168px;}
.paymentsPage .mainFlexible .mainContainer {max-width: 1220px;}
.paymentsPage .mainFlexible .mainContent {display: flex;flex-wrap: wrap;}
.paymentsPage .mainFlexible .division {width: 100%;max-width: calc(100%/2);float: left;}
.paymentsPage .mainFlexible h2 {font-size: 32px;line-height: 48px;}
.paymentsPage .mainFlexible .mainP {margin-top: 20px;max-width: 400px;}
.paymentsPage .mainFlexible .ctaBlue {margin-top: 30px;}
.paymentsPage .mainFlexible #right img {max-width: 275px;width: 100%;margin-right: 0;margin-left: auto;display: block;}
.paymentsPage .mainFlexible .division#left {display: grid;align-content: center;align-items: center;}
.paymentsPage .mainFlexible .division#left .divisionIn {max-width: 450px;margin-left: 0px;margin-right: auto;}
.paymentsPage .mainFeedback {padding: 160px 0px 183px 0px;background-color: #fff;}
.paymentsPage .mainFeedback .mainContainer {max-width: 1180px;}
.paymentsPage .mainFeedback h2 {text-align: center;font-size: 32px;line-height: 38px;}
.paymentsPage .mainFeedback .slickWrap {margin-top: 65px;}
.paymentsPage .mainFeedback .slickWrap .singleIn .head {display: flex;}
.paymentsPage .mainFeedback .slickWrap .singleIn .head .person {display: grid;align-content: center;padding-right: 16px;}
.paymentsPage .mainFeedback .slickWrap .singleIn .head .personImg {width: 48px;height: 48px;border-radius: 50%;background-position: center;background-size: cover;}
.paymentsPage .mainFeedback .slickWrap .singleIn .head .info {display: grid;align-content: center;align-items: center;}
.paymentsPage .mainFeedback .slickWrap .singleIn .head .info .name {font-weight: 700;font-size: 14px;line-height: 23px;color: #1A1A1A;}
.paymentsPage .mainFeedback .slickWrap .singleIn .head .info .role {font-size: 13px;line-height: 23px;}
.paymentsPage .mainFeedback .slickWrap .singleIn .testimonialWrap {margin-top: 20px;}
.paymentsPage .mainFeedback .slickWrap .singleIn .testimonialWrap p {font-size: 13px;line-height: 27px;}
.paymentsPage .mainFeedback .slickWrap .slick-list {padding: 20px 0px;}
.paymentsPage .mainFeedback .slickWrap .slick-slide {margin: 0px 22px;box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.4);border-radius: 5px;padding: 24px 24px 30px 24px;height: inherit;}
.paymentsPage .mainFeedback .slickWrap .slick-track {display: flex !important}
.paymentsPage .mainInstall {background: rgb(57,222,226);background: linear-gradient(17deg, rgba(57,222,226,1) 0%, rgba(68,220,183,1) 76%); padding: 116px 0 120px}
.paymentsPage .mainInstall .mainContainer {max-width: 1295px;}
.paymentsPage .mainInstall .mainContent {background: rgba(255, 255, 255, 0.8);opacity: 1;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 38px rgba(0, 0, 0, 0.25);border-radius: 9px;padding: 64px 61px 73px 77px;position: relative;}
.paymentsPage .mainInstall h2 {width: 100%;max-width: 310px;}
.paymentsPage .mainInstall .box {display: flex;justify-content: end;position: absolute;right: 63px;top: 57px;max-width: 645px;width: 100%;}
.paymentsPage .mainInstall .box .mainP {display: grid;align-content: center;padding-right: 29px;text-align: right;}
.paymentsPage .mainInstall .box .ctaBlue {width: 100%;max-width: 160px;text-align: center;display: grid;align-content: center;align-items: center;}
.paymentsPage .mainInstall .steps {position: relative;margin-top: 74px;}
.paymentsPage .mainInstall .steps .bar {height: 11px;background: linear-gradient(127.45deg, #44DCB7 23.85%, #35E0F4 96.39%, rgba(68, 220, 183, 0) 100%);position: absolute;top: 26px;width: 100%;left: 63px;}
.paymentsPage .mainInstall .count1 .bar {display: none;}
.paymentsPage .mainInstall .count2 .bar {max-width: 500px;}
.paymentsPage .mainInstall .count3 .bar {max-width: 690px;}
.paymentsPage .mainInstall .count4 .bar {max-width: 790px;}
.paymentsPage .mainInstall .count5 .bar {max-width: 835px;}
.paymentsPage .mainInstall .count6 .bar {max-width: 875px;}
.paymentsPage .mainInstall .count7 .bar {max-width: 890px;}
.paymentsPage .mainInstall .steps .stepsWrap {display: flex;width: 100%;}
.paymentsPage .mainInstall .steps .stepsWrap .step {flex: 1;}
.paymentsPage .mainInstall .steps .stepsWrap .stepIn {width: 100%;}
.paymentsPage .mainInstall .steps .stepsWrap .stepIn .number {height: 63px;width: 63px;display: grid;background-color: rgba(255, 255, 255, 0.9);border-radius: 50%;align-content: center;text-align: center;font-size: 40px;font-weight: 700;padding-top: 6px;box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);z-index: 1;position: relative;}
.paymentsPage .mainInstall .steps .stepsWrap .stepIn p {font-size: 16px;line-height: 24px;letter-spacing: 4px;font-weight: 700;text-transform: uppercase;margin-top: 30px;word-wrap: anywhere;}
.paymentsPage .mainInstall .count1 .stepsWrap .stepIn {padding-right: 55px;}
.paymentsPage .mainInstall .count2 .stepsWrap .stepIn {padding-right: 55px;}
.paymentsPage .mainInstall .count3 .stepsWrap .stepIn {padding-right: 55px;}
.paymentsPage .mainInstall .count4 .stepsWrap .stepIn {padding-right: 55px;}
.paymentsPage .mainInstall .count5 .stepsWrap .stepIn {padding-right: 55px;}
.paymentsPage .mainInstall .count6 .stepsWrap .stepIn {padding-right: 40px;}
.paymentsPage .mainInstall .count7 .stepsWrap .stepIn {padding-right: 40px;}

@media screen and (min-width: 1750px) {
    .paymentsPage .mainHero .mainContent .division#right .divisionIn > img {margin-right: -110px !important;width: 100% !important;}
    .paymentsPage .mainHero h1 {max-width: 100% !important}
}

@media screen and (max-width: 1600px) {
    .paymentsPage .mainHero .mainContent .division#right .divisionIn > img {margin-right: -26%;width: 706px;}
    .paymentsPage .mainHero .mainContent .division#left .divisionIn {max-width: 545px;}
    .paymentsPage .mainIntegrate .mainContent .division#right .divisionIn {max-width: 500px;}
    .paymentsPage .mainTransaction .mainContainer, 
    .paymentsPage .mainNewsletter .mainContainer, 
    .paymentsPage .mainFlexible .mainContainer {max-width: 1020px;}
}

@media screen and (max-width: 1200px) {
    .paymentsPage .mainHero {padding-top: 60px !important;}
    .paymentsPage .mainHero .mainContent .division#right .divisionIn > img {margin: 70px auto 0px !important;display: block;width: 100%;max-width: 706px;}
    .paymentsPage .mainHero .mainContent .division#right .divisionIn {display: grid;}
    .paymentsPage .mainHero .mainContent .division#left .divisionIn {padding-top: 10px;}
    .paymentsPage .mainHero .mainContent .division#left {padding-top: 30px;}
    .paymentsPage .mainIntegrate {padding-top: 70px;}
    .paymentsPage .mainIntegrate .mainContent {display: block;}
    .paymentsPage .mainIntegrate .mainContent .division {max-width: 100%;float: none;}
    .paymentsPage .mainIntegrate .mainContent .division#left img {margin: 0 auto;display: block;}
    .paymentsPage .mainIntegrate .mainContent .division#right .divisionIn {max-width: 100%;padding-top: 45px;}
    .paymentsPage .mainTransaction {padding-top: 65px;background: #f1f1f1;}
    .paymentsPage .mainTransaction .allFeatures {margin-top: 0px;}
    .paymentsPage .mainNewsletter {padding-top: 79px;}
    .paymentsPage .mainFlexible {padding-bottom: 68px;padding-top: 45px;}
    .paymentsPage .mainFeedback .slickWrap {margin-top: 8px;}
    .paymentsPage .mainFeedback {padding: 56px 0px 46px 0px;}
    .paymentsPage .mainFeedback .slickWrap {padding: 0px 40px;}
    .paymentsPage .mainInstall {padding: 64px 0px;}
    .paymentsPage .mainInstall .steps .stepsWrap {display: block;}
    .paymentsPage .mainInstall .mainContent {padding: 39px 34px 39px 34px;}
    .paymentsPage .mainInstall .steps .bar {height: 79%;width: 100%;max-width: 11px !important;left: 23px;}
    .paymentsPage .mainInstall .steps .stepsWrap .stepIn {padding-right: 0px;}
    .paymentsPage .mainInstall .steps .stepsWrap .stepIn p {margin-top: -39px;padding-left: 79px;padding-bottom: 60px;}
    .paymentsPage .mainInstall .steps .stepsWrap .stepIn p br {display: none}
    .paymentsPage .mainInstall .box {display: block;position: initial;width: 100%;max-width: 100%;margin-top: 20px;}
    .paymentsPage .mainInstall .box .ctaBlue {margin-top: 9px;}
    .paymentsPage .mainInstall .box .mainP {text-align: left;}
    .paymentsPage .mainInstall .steps {margin-top: 50px;}
    .paymentsPage .mainInstall .steps .stepsWrap .stepIn .number {font-size: 27px;height: 55px;width: 55px;}
    .paymentsPage .mainInstall .steps .stepsWrap .step:last-child .stepIn p {padding-bottom: 30px;}
}

@media screen and (max-width: 991px) {
    .paymentsPage .mainHero {padding-top: 45px !important;}
    .paymentsPage .mainFlexible .mainContent {display: flow-root;}
    .paymentsPage .mainFlexible .division {max-width: 100%;}
    .paymentsPage .mainFlexible #right img {display: block;margin: 40px auto 0px auto;}
    .paymentsPage .mainFeedback .slickWrap {padding: 0px 0px;}
    .paymentsPage .slick-slider img#slickPrev {left: -15px;}
    .paymentsPage .slick-slider img#slickNext {right: -15px;}
}